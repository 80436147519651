var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "70%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        title: _vm.currentRole.id
          ? _vm.$t("lang_edit_client")
          : _vm.$t("lang_add_client"),
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "formRef",
          attrs: {
            model: _vm.entrustData,
            "label-suffix": "：",
            rules: _vm.formRules,
          },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_delegation_process"),
                        prop: "processCode",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c("treeselect", {
                        ref: "processRef",
                        attrs: {
                          normalizer: _vm.formNormalizer,
                          appendToBody: "",
                          multiple: true,
                          zIndex: "9999",
                          "disable-branch-nodes": true,
                          options: _vm.processList,
                        },
                        model: {
                          value: _vm.entrustData.entrustProcess,
                          callback: function ($$v) {
                            _vm.$set(_vm.entrustData, "entrustProcess", $$v)
                          },
                          expression: "entrustData.entrustProcess",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_start_time"),
                            prop: "startTime",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              placement: "bottom",
                              type: "datetime",
                              placeholder: _vm.$t("lang_please_select_date"),
                              "value-format": "timestamp",
                            },
                            model: {
                              value: _vm.entrustData.startTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.entrustData, "startTime", $$v)
                              },
                              expression: "entrustData.startTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("lang_the_end_of_time") } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              placeholder: _vm.$t("lang_please_select_date"),
                              "value-format": "timestamp",
                            },
                            model: {
                              value: _vm.entrustData.endTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.entrustData, "endTime", $$v)
                              },
                              expression: "entrustData.endTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_principal"),
                        "label-width": "100px",
                      },
                    },
                    [
                      !_vm.showIdentityScope
                        ? _c("div", {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: true,
                                expression: "true",
                              },
                            ],
                            staticStyle: { width: "100%", height: "600px" },
                          })
                        : _vm._e(),
                      _vm.showIdentityScope
                        ? _c("bind-scope", {
                            attrs: {
                              tenantId: _vm.tenant ? _vm.tenant.id : "",
                              "select-scope-list": _vm.selectedScopeList,
                            },
                            on: { done: _vm.updateSelectDeptUser },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveForm } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }