










































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { EntrustDatas, EntrustModel } from '@/models/system/EntrustModel';
import { RoleSelectDeptUserModel } from '@/models/system/RoleModel';
import { tree, save, detail } from '@/api/delegation/EntrustApi';
import BindScope from '@/components/bind-scope/index.vue';
import { TenantModel } from '@/models/system/TenantModel';

@Component({
  name: 'EntrustForm',
  components: {
    BindScope
  }
})
export default class EntrustForm extends Vue {
  // ref
  @Ref() readonly formRef;
  // 角色id
  @Prop({ default: '' }) currentRole!: any;

  // prop
  // 弹窗显隐
  @Prop({ default: false }) visible!: boolean;
  // 租户id
  @Prop({ default: null }) tenant!: TenantModel;

  // 选择的部门、用户列表
  selectedScopeList: RoleSelectDeptUserModel[] = [];

  // 角色新增/编辑表单实体
  entrustData: EntrustModel = EntrustDatas;
  // 表单加载状态
  formLoading: boolean = false;
  // 表单验证规则
  formRules: Record<string, any> = {
    name: {
      required: true,
      message: this.$t('lang_fill_in_role_name'),
      trigger: 'blur'
    },
    startTime: {
      required: true,
      message: this.$t('lang_please_select_start_time'),
      trigger: 'change'
    }
  };
  // 流程列表，用于数据源类型为 意见时
  processList: any[] = [];
  // 是否显示身份范围组件
  showIdentityScope: boolean = false;

  /**
   * 监听弹窗显示隐藏
   * @param val
   */
  @Watch('visible')
  visibleWatcher(val) {
    if (val) {
      this.getTree();
      this.selectedScopeList = [];
      if (this.currentRole?.id) {
        this.entrustData = this.currentRole;
        detail(this.entrustData.acceptorScope).then((res: Record<any, any>) => {
          let data = res.data;
          if (res.code === '1' && data) {
            let groupList = data.groups;
            let userList = data.users;
            if (groupList) {
              groupList.forEach(item => {
                let row: RoleSelectDeptUserModel = {
                  id: item.id,
                  type: 'org',
                  name: item.name
                };
                this.selectedScopeList.push(row);
              });
            }

            if (userList) {
              userList.forEach(item => {
                let row: RoleSelectDeptUserModel = {
                  id: item.id,
                  type: 'user',
                  name: item.name
                };
                this.selectedScopeList.push(row);
              });
            }
          }
        });
        // 显示身份范围组件组件
        this.showIdentityScope = true;
      } else {
        // 显示身份范围组件组件
        this.showIdentityScope = true;
      }
    } else {
      this.selectedScopeList = [];
      this.entrustData = EntrustDatas;
      this.entrustData.endTime = null;
      this.entrustData.entrustProcess = [];
      // 清理表单验证
      this.formRef.resetFields();
    }
  }

  // 获取流程树
  getTree() {
    tree({ linkType: 2 }).then((res: Record<any, any>) => {
      this.processList = res.data;
    });
  }

  /**
   * 接收绑定范围组件传过来的值
   * @param val
   */
  updateSelectDeptUser(val) {
    this.selectedScopeList = val;
  }

  /**
   * 表单树格式器
   * @param {number} param
   * @returns {number}
   */
  formNormalizer(node) {
    return {
      id: node.id,
      label: node.text || this.$t(node.text),
      children: node.children
    };
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  /**
   * update visible
   * @param value
   */
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  /**
   * 保存表单
   */
  saveForm() {
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      if (this.selectedScopeList.length == 0) return this.$message.warning(this.$t('lang_please_select_principal') as string);
      // 构建请求数据组合身份范围参数
      let userIds: any[] = [];
      let groupIds: any[] = [];
      this.selectedScopeList.forEach(item => {
        if (item.type === 'user') {
          userIds.push(item.id);
        } else if (item.type === 'org') {
          groupIds.push(item.id);
        }
      });
      this.entrustData.acceptorScope = { userIds, groupIds };
      let result;
      if (this.currentRole?.id) {
        result = save(this.entrustData);
      } else {
        result = save(this.entrustData);
      }

      result
        .then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            // 关闭弹窗
            this.handleCloseDialog();
            this.$emit('success', res.data);
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          // 关闭加载框
        });
    });
  }

  get dialogVisible() {
    return this.visible;
  }

  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
