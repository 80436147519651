/**
 * 委托实体
 */
export interface EntrustModel {
    id?: string;
    tenantId?: string;
    entrustUserId: string;
    acceptorScope: IdentityScopeMode;
    startTime: number;
    endTime: number;
    entrustProcess: any[];
}

/**
 * 身份范围实体
 */
 export interface IdentityScopeMode {
    userIds?: string[];//人员
    groupIds?: string[];//组织，角色
  }

  export const EntrustDatas: EntrustModel = {
    id: '',
    tenantId: '',
    entrustUserId: '',
    acceptorScope: {
      userIds: [],
      groupIds: []
    },
    startTime: null,
    endTime: null,
    entrustProcess: [],
  };