










































































import { Component, Vue } from 'vue-property-decorator';
import { getPage, deleteEntrust } from '@/api/delegation/EntrustApi';
import EntrustForm from '@/views/delegation/entrust-form.vue';
import DateUtil from '@/utils/DateUtil';
@Component({
  name: 'EntrustList',
  components: {
    EntrustForm
  }
})
export default class EntrustList extends Vue {
  // 表单搜索Model
  searchForm: any = {};
  // 列表加载状态
  tableLoading: boolean = false;
  // 是否显示新增/编辑角色弹窗
  formDiglogVisible: boolean = false;
  // 授权列表
  entrustList: any[] = [];
  // 分页参数
  pageIndex: number = 1;
  // 每页大小
  pageSize: number = 10;
  // 总条数
  total: number = 0;
  // 当前页
  currentPage: number = 1;
  // 委托人id
  entrustUserId: string = '';
  currentRole: any = {};

  /**
   * onMounted
   */
  mounted() {
    // 获取角色列表
    this.getEntrustList();
  }

  // 新增维权
  openForm(row?: any) {
    if (row) {
      this.currentRole = row;
    }
    this.formDiglogVisible = true;
  }

  // 时间戳改为时间
  setTime(d) {
    return format(d);
    function format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
    }
    function add0(m) {
      return m < 10 ? '0' + m : m;
    }
  }
  // 删除
  deleteEntrusts(row?: any) {
    if (row) {
      let rid = row.id;
      this.$confirm(this.$t('lang_whether_or_not_to_delete') as string, this.$t('lang_tips') as string, {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        type: 'warning'
      })
        .then(() => {
          deleteEntrust({ processEntrustId: rid })
            .then((res: Record<any, any>) => {
              if (res.code == '1') {
                this.$message.success(res.message);
                this.getEntrustList();
              } else {
                this.$message.error(res.message);
              }
            })
            .finally(() => {});
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: this.$t('lang_undeleted') as string
          });
        });
    }
  }

  /**
   * 获取角色列表
   */
  getEntrustList() {
    let params = {
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.tableLoading = true;
    getPage(params)
      .then((res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.entrustList = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        this.tableLoading = false;
      });
  }

  /**
   * 改变分页大小
   */
  changePageSize(pageSize: number) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getEntrustList();
  }

  /**
   * 跳转至某页
   */
  changePage(page: number) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getEntrustList();
  }
}
