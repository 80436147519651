import HttpUtil from '@/utils/HttpUtil';

// 获取分页列表
export const getPage = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'front/process/entrust/query/page';
  return HttpUtil.post(url, params);
};

// 获取用户信息
export const entrustUsers = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'front/process/entrust/query/entrustUsers';
  return HttpUtil.get(url, params);
};

// 保存委托
export const save = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'front/process/entrust/save';
  return HttpUtil.post(url, params);
};

// 委托流程树
export const tree = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + '/application/link/query/code/tree';
  return HttpUtil.get(url, params);
};

// 删除委托
export const deleteEntrust = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + '/front/process/entrust/delete';
  return HttpUtil.get(url, params);
};

// 委托流程树
export const detail = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + '/id/query/scope/detail';
  return HttpUtil.post(url, params);
};
